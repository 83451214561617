import { lazy } from 'react';

import AuthProtect from 'src/components/Auth/AuthProtect';
import { LayoutDefault } from 'src/layouts/Default';
import { PATH_APP } from 'src/routes/paths';
import { Loadable } from 'src/components/loadable';
import { WithProposalActionsRender } from 'src/hooks/proposalActions';
import { WithSLARender } from 'src/hooks/sla';

const ProposalsList = Loadable(lazy(() => import('./list')));
const ProposalsDetails = Loadable(lazy(() => import('./detail')));

const proposalsRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <WithProposalActionsRender>
        <WithSLARender>
          <LayoutDefault />
        </WithSLARender>
      </WithProposalActionsRender>
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.proposals.root,
      element: <ProposalsList />
    },
    {
      path: PATH_APP.proposals.details,
      element: <ProposalsDetails />
    }
  ]
};

export default proposalsRoutes;
