import { lazy } from 'react';

import GuestProtect from 'src/components/Auth/GuestProtect';
import { PATH_PAGE } from 'src/routes/paths';
import { Loadable } from 'src/components/loadable';

const Login = Loadable(lazy(() => import('./login')));

const authRoutes = {
  path: PATH_PAGE.auth.root,
  children: [
    {
      path: PATH_PAGE.auth.login,
      element: (
        <GuestProtect>
          <Login />
        </GuestProtect>
      )
    }
  ]
};

export default authRoutes;
