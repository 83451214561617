function path<Root extends string, Sublink extends string>(
  root: Root,
  sublink: Sublink
): `${Root}${Sublink}` {
  return `${root}${sublink}`;
}

const ROOTS = {
  app: '/',
  auth: '/auth'
} as const;

export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login')
  }
} as const;

export const PATH_APP = {
  root: ROOTS.app,
  general: {
    root: path(ROOTS.app, 'dashboard'),
    dashboard: path(ROOTS.app, 'dashboard'),
    account: path(ROOTS.app, 'minha-conta')
  },
  proposals: {
    root: path(ROOTS.app, 'propostas'),
    details: path(ROOTS.app, 'propostas/:id')
  },
  producers: {
    root: path(ROOTS.app, 'produtores'),
    details: path(ROOTS.app, 'produtores/clientes/:id')
  },
  advisors: {
    root: path(ROOTS.app, 'assessores'),
    details: path(ROOTS.app, 'assessores/:id'),
    edit: path(ROOTS.app, 'assessores/:id/editar'),
    missions: path(ROOTS.app, 'assessores/:id/missoes')
  },
  peoplePhysical: {
    root: path(ROOTS.app, 'pessoas_fisicas'),
    details: path(ROOTS.app, 'pessoas_fisicas/:id')
  },
  juridicalPerson: {
    consult: path(ROOTS.app, 'pessoas-juridicas/consultar')
  }
} as const;
