import { lazy } from 'react';

import AuthProtect from 'src/components/Auth/AuthProtect';
import { LayoutDefault } from 'src/layouts/Default';
import { PATH_APP } from 'src/routes/paths';
import { Loadable } from 'src/components/loadable';

const PeoplePhysicalList = Loadable(lazy(() => import('./list')));
const PeoplePhysicalDetails = Loadable(lazy(() => import('./detail')));

const peoplePhysicalRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.peoplePhysical.root,
      element: <PeoplePhysicalList />
    },
    {
      path: PATH_APP.peoplePhysical.details,
      element: <PeoplePhysicalDetails />
    }
  ]
};

export default peoplePhysicalRoutes;
