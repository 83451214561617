import { Navigate, useRoutes } from 'react-router-dom';

import authRoutes from 'src/modules/auth/auth.routes';
import generalRoutes from 'src/modules/general/general.routes';
import proposalsRoutes from 'src/modules/proposals/proposals.routes';
import producersRoutes from 'src/modules/producers/producers.routes';
import advisorsRoutes from 'src/modules/advisors/advisors.routes';
import juridicalPersonRoutes from 'src/modules/juridicalPerson/juridicalPerson.routes';
import peoplePhysicalRoutes from 'src/modules/peoplePhysical/peoplePhysical.routes';
import NotFound from 'src/pages/NotFound';

export default function Router() {
  return useRoutes([
    authRoutes,
    generalRoutes,
    proposalsRoutes,
    producersRoutes,
    advisorsRoutes,
    juridicalPersonRoutes,
    peoplePhysicalRoutes,
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
