import { lazy } from 'react';

import AuthProtect from 'src/components/Auth/AuthProtect';
import { LayoutDefault } from 'src/layouts/Default';
import { PATH_APP } from 'src/routes/paths';
import { Loadable } from 'src/components/loadable';

const ProducersList = Loadable(lazy(() => import('./list')));
const ProducersDetails = Loadable(lazy(() => import('./detail')));

const producersRoutes = {
  path: PATH_APP.root,
  element: (
    <AuthProtect>
      <LayoutDefault />
    </AuthProtect>
  ),
  children: [
    {
      path: PATH_APP.producers.root,
      element: <ProducersList />
    },
    {
      path: PATH_APP.producers.details,
      element: <ProducersDetails />
    }
  ]
};

export default producersRoutes;
